/* overall flex container*/

.container-flex{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden
}

/*img container size with aspect ratio*/

.img-container{
    height: 104px;
    margin: 5px; 
    width: 168px;
}

.img-container img {
    border-radius: 3px;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}


